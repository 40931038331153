footer {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 0 desktop_size(97) desktop_size(62) desktop_size(260);
  z-index: 2;
  .privacy {
    display: flex;
    justify-content: space-between;
    .footer-link {
      margin: 0 desktop_size(23);
      cursor: pointer;
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
      &:hover {
        color: $footer-text-hover-color
      }
    }
  }
  .rights {
    line-height: 180%;
  }
}