@media screen and (max-width: 1024px) {
  .contact-us-wrapper {
    height: tablet_size(573);
    padding-top: tablet_size(13);
    #map {
      width: 57%;
      height: tablet_size(695);
    }
    .map-bg {
      pointer-events: none;
      width: 100%;
      height: tablet_size(700);
      position: absolute;
      bottom: 0;
      right: 0;
      background-image: url("../img/bg-tablet.png");
      background-size: cover;
    }
    .contact-us {
      padding: 0 0 tablet_size(213) tablet_size(100);

      &.slide-up {

        h6 {
          margin-top: tablet_size(40);
        }

        button {
          margin-top: tablet_size(38);
        }

        .text {
          &:nth-child(3) {
            width: tablet_size(275);
            max-width: tablet_size(275);
          }
        }

      }

      .text {
        &:nth-child(3) {
          margin-top: tablet_size(50);
        }
      }

      h6 {
        margin-top: tablet_size(70);
      }

      button {
        margin-top: tablet_size(65);
        height: tablet_size(60);
        padding: 0 tablet_size(43);
      }
    }
  }
}