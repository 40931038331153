@media screen and (max-width: 1024px) {
  .about-us-wrapper {
    padding: tablet_size(209) 0 tablet_size(121);
    flex-wrap: wrap;
    justify-content: center;
    &.after-load {
      .about-us {
        button {
          margin-top: tablet_size(30);
        }
      }

      .first-animation {
        width: tablet_size(700);
        margin-top: tablet_size(110);
        flex: 0 0 tablet_size(700);
      }
    }

    .about-us {
      padding-top: 0;
      width: auto;
      text-align: center;

      h1 {
        max-width: tablet_size(635);
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        span {
          width: auto;
        }
      }

      .text {
        max-width: tablet_size(515);
        text-align: center;
        margin-right: auto;
        margin-left: auto;
        font-size: tablet_size(18);
      }

      button {
        height: tablet_size(68);
        padding: 0 tablet_size(53);
        margin-left: auto;
        margin-right: auto;
        margin-top: tablet_size(30);
      }
    }

    .first-animation {
      width: tablet_size(700);
      margin-top: tablet_size(110);
      opacity: 0;
    }
  }
}