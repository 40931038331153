.services-wrapper {
  overflow: hidden;
  background: 4.3229vw 9.2708vw / 90% auto no-repeat url("../img/circles-2.png");
  height: desktop_size(1750);

  .title {
    .section-indicator {
      margin-left: auto;
      margin-right: auto;
    }

    h3 {
      text-align: center;
    }

    .text {
      text-align: center;
      margin: 0 auto;
      width: desktop_size(340);
    }
  }

  .services-list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: desktop_size(47) desktop_size(260) desktop_size(12);
    &.slider {
      display: none;
    }

    &.slide-up {
      .services-list-item {
        margin: desktop_size(35) 0;
        opacity: 1;
        transition-timing-function: ease-out;
        transition: 1.5s;
      }
    }

    .services-list-item {
      width: desktop_size(400);
      height: desktop_size(430);
      box-shadow: 0 desktop_size(4) desktop_size(70) rgba(172, 185, 192, 0.25);
      border-radius: desktop_size(50);
      padding: desktop_size(80) desktop_size(75) 0 desktop_size(70);
      margin-top: desktop_size(100);
      opacity: 0;
      transition-timing-function: ease-out;
      transition: 1.5s;

      &:hover {
        box-shadow: 0px desktop_size(20) desktop_size(20) rgba(75, 97, 195, 0.2);
        transition: 0.3s;
      }

      .service-icon {
        width: desktop_size(60);
        height: desktop_size(60);
        border-radius: 50%;
        background-position: center;
        background-repeat: no-repeat;
      }

      .text {
        font-size: desktop_size(14);
        opacity: 0.85;
      }

      &:first-child {
        .service-icon {
          background: url("../img/service-1.svg") center no-repeat;
        }
      }

      &:nth-child(2) {
        transition-delay: 0.3s;

        .service-icon {
          background: url("../img/service-2.svg") center no-repeat;
        }
      }

      &:nth-child(3) {
        transition-delay: 0.6s;

        .service-icon {
          background: url("../img/service-3.svg") center no-repeat;
        }
      }

      &:nth-child(4) {
        transition-delay: 0.9s;

        .service-icon {
          background: url("../img/service-4.svg") center no-repeat;
        }
      }

      &:nth-child(5) {
        transition-delay: 1.2s;

        .service-icon {
          background: url("../img/service-5.svg") center no-repeat;
        }
      }

      &.contact {
        padding-top: desktop_size(95);
        padding-left: desktop_size(82);
        background-color: $services-contact-bg-color;

        button {
          background-color: $button-services-bg-color;
          color: $button-services-text-color;
          &:hover {
            color: $white-text;
          }
        }
      }
    }
  }

  .second-animation {
    width: desktop_size(981.07);
    margin: 0 auto desktop_size(34);
  }
}