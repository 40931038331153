@media screen and (max-width: 760px) {
  footer {
    pointer-events: none;
    padding: 0 0 6.4vw;
    justify-content: center;

    .privacy {
      display: none;
    }

    .rights {
      line-height: 180%;
    }
  }
}