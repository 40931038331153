.about-us-wrapper {
  padding: desktop_size(200) desktop_size(73.19) desktop_size(213) desktop_size(200);
  position: relative;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  align-items: flex-start;
  &.after-load {
    .about-us {
      h1 {
        transition-duration: 3s;
        opacity: 1;
      }
      .text {
        transition-delay: 0.5s;
        transition-duration: 3s;
        margin-top: 0;
        opacity: 1;
      }
      button {
        transition-delay: 1s;
        transition-duration: 2s;
        margin-top: desktop_size(50);
        opacity: 1;
      }
    }
    .first-animation {
      transition-delay: 1s;
      transition-duration: 1.5s;
      opacity: 1;
      width: desktop_size(916.81);
      flex: 0 0 desktop_size(916.81);
    }
  }
  .about-us {
    padding-top: desktop_size(89);
    width: auto;
    flex: 1 1 auto;
    h1 {
      opacity: 0;
      max-width: desktop_size(660);
      span {
        max-width: desktop_size(500);
      }
    }
    .text {
      opacity: 0;
      margin-top: desktop_size(50);
      line-height: 180%;
      max-width: desktop_size(650);
    }
    button {
      margin-top: desktop_size(80);
      opacity: 0;
      height: desktop_size(80);
      padding: 0 desktop_size(56);
      border-radius: desktop_size(15);
    }
  }
  .first-animation {
    opacity: 0;
    width: desktop_size(916.81);
    flex: 0 0 desktop_size(916.81);
  }
}