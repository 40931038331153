
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;

  &::-webkit-scrollbar {
    width: 0;
  }
  * {
    box-sizing: border-box;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;


    &::-webkit-scrollbar {
      width: 0;
    }
  }

  .page-wrapper {
    //max-width: 1920px;
    margin: 0 auto;
    position: relative;
    background: left 7.0313vw / auto 24.2188vw no-repeat url("../img/rectangle-1.png"),
    right 47.3958vw / auto 24.2188vw no-repeat url("../img/rectangle-2.png"),
    right 115.625vw / auto 18.8542vw no-repeat url("../img/rectangle-3.png"),
    left 119.2708vw / auto 21.5625vw no-repeat url("../img/rectangle-4.png"),
    left 264.0625vw / auto 24.2188vw no-repeat url("../img/rectangle-5.png"),
    3.5938vw 8.4375vw / 90% auto no-repeat url("../img/circles-1.png");
    a {
      text-decoration: none;
    }
    button {
      border: none;
      color: $button-text-color;
      background-color: $button-bg-color;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      height: desktop_size(60);
      padding: 0 desktop_size(38);
      font-family: Gilroy-semibold, serif;
      font-size: desktop_size(18);
      border-radius: desktop_size(10);
      outline: 0;
      cursor: pointer;
      transition: 0.5s;
      text-transform: capitalize;
      &:hover {
        transition: 0.5s;
        background-image: $button-hover-bg-color;
        box-shadow: 0px 20px 20px rgba(75, 97, 195, 0.2)
      }
      &:active {
        box-shadow: 0px 20px 20px rgba(75, 97, 195, 0.25);
        transition: 0.5s;
      }
    }
    .section-indicator {
      width: desktop_size(107);
      height: desktop_size(9);
      background: $section-indicator-bg;
      margin-bottom: desktop_size(32);
    }
  }
}