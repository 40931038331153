@media screen and (max-width: 1024px) {
  .services-wrapper {
    background: 4.9805vw 9.2708vw / 90% auto no-repeat url("../img/circles-2.png");
    height: tablet_size(2025);

    .title {

      .text {
        max-width: tablet_size(275);
        width: tablet_size(275);
      }
    }

    .services-list {
      display: flex;
      justify-content: space-between;
      padding: tablet_size(25) tablet_size(72) 0;

      &.slide-up {
        .services-list-item {
          margin: tablet_size(40) 0;
        }
      }

      .services-list-item {
        max-width: 46%;
        width: tablet_size(400);
        min-height: tablet_size(400);
        height: auto;
        border-radius: tablet_size(50);
        padding: tablet_size(65) tablet_size(61) tablet_size(30) tablet_size(83);

        .service-icon {
          width: tablet_size(60);
          height: tablet_size(60);
        }

        .text {
          font-size: tablet_size(14);
          opacity: 0.85;
        }


        &.contact {
          padding-top: tablet_size(85);

          button {
            background-color: $button-services-bg-color;
            color: $button-services-text-color;
          }
        }
      }
    }

    .second-animation {
      width: tablet_size(753);
      margin: 0 auto;
    }
  }
}