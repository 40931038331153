@media screen and (max-width: 760px) {
  header {
    padding: mob_size(13) mob_size(23);

    .logo {
      width: mob_size(90);
      height: mob_size(22);
    }

    menu {
      display: flex;
      left: 0;
      width: 100%;
      position: absolute;
      align-items: flex-start;
      list-style: none;
      margin: 0;
      padding: mob_size(52) 0;
      height: calc(100vh - 15vw);
      overflow: hidden;
      transition: 0.3s;
      top: mob_size(55);
      flex-direction: column;
      background-color: white;
      opacity: 0;
      pointer-events: none;

      &.open {
        transition: 0.3s;
        opacity: 1;
        pointer-events: all;
      }

      li {
        opacity: 0;
        margin: 0;
        border-bottom: 2px solid transparent;
        width: 100%;
        padding: 0 mob_size(30);

        .menu-text {
          padding: mob_size(11) 0;
          display: inline-block;
          text-transform: capitalize;
        }

        &:hover {
          .menu-text {
            color: $menu-item-text-hover-color;
          }
        }

        &:active, &.active {
          border-color: transparent;

          .menu-text {
            color: $menu-item-active-color;
          }
        }

        &:first-child {
          margin-left: 0;
        }

        &:nth-child(5) {
          margin-right: 0;

          .menu-text {
            display: inline-block;
            background-color: transparent;
            color: $menu-item-text-color;

            &:hover {
              background: none;
              box-shadow: none;
            }

            &:active, &.active {
              background: none;
              box-shadow: none;
            }

            &:first-child {
              display: inline-block;
            }

            &:last-child {
              display: none;
            }

          }
        }

        &:nth-child(6),
        &:nth-child(7),
        &:nth-child(8) {
          display: list-item;
        }
      }

      ul {
        border-top: 2px solid rgba(186, 194, 232, 0.2);
        position: absolute;
        bottom: mob_size(20);
        left: 0;
        width: 100%;
        list-style: none;
        margin: 0;
        padding: mob_size(35) 0 0;
      }
    }

    input {
      display: none;
    }

    input + label {
      margin-top: mob_size(10);
      position: relative;
      height: mob_size(14);
      width: mob_size(24);
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: space-between;
      z-index: 5;

      span {
        width: 100%;
        height: 2px;
        display: block;
        background: $menu-burger-color;
        transition: .5s;
      }

      span:first-child {
        width: 66%;
      }

      span:last-child {
        width: 66%;
      }
    }

    label:hover {
      cursor: pointer;
    }

    input:checked + label {
      span {
        position: absolute;
        opacity: 0;
        top: 50%;
      }

      span:first-child {
        opacity: 1;
        transform: rotate(45deg);
      }

      span:last-child {
        opacity: 1;
        transform: rotate(-45deg);
      }
    }
  }
}