@media screen and (max-width: 760px) {
  .contact-us-wrapper {
    height: mob_size(783);
    padding-top: mob_size(25);

    #map {
      position: absolute;
      width: 100%;
      height: mob_size(350);
    }
    .map-bg {
      pointer-events: none;
      width: 100%;
      height: mob_size(137);
      position: absolute;
      bottom: mob_size(253);
      right: 0;
      background-image: url("../img/bg-mob.png");
      background-size: cover;
    }
    .contact-us {
      padding: mob_size(50) 0 0;
      text-align: center;
      width: 100%;

      &.slide-up {

        h6 {
          margin-top: mob_size(50);
        }

        button {
          margin-top: mob_size(35);
          padding: 0 mob_size(39);
        }

        .text {
          &:nth-child(3) {
            max-width: unset;
            width: mob_size(260);
          }
        }

      }

      .section-indicator {
        margin-right: auto;
        margin-left: auto;
      }

      h3 {
        text-align: center;
      }

      .text {
        width: mob_size(260);
        margin-right: auto;
        margin-left: auto;
        text-align: center;
      }
      .email-text {
        font-size: mob_size(18);
      }

      h6 {
        margin-top: mob_size(80);
        text-align: center;
      }

      button {
        margin-top: mob_size(60);
        height: mob_size(60);
      }
    }
  }
}