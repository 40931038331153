.contact-us-wrapper {
  padding-bottom: desktop_size(234);
  overflow: hidden;
  height: desktop_size(795);
  #map {
    position: absolute;
    bottom: 0;
    right: 0;
    width: desktop_size(1115);
    height: desktop_size(885);
  }
  .map-bg {
    pointer-events: none;
    width: 100%;
    height: desktop_size(888);
    position: absolute;
    bottom: 0;
    right: 0;
    background-image: url("../img/bg.png");
    background-size: cover;
    background-position: left;
  }
  .contact-us {
    position: relative;
    width: 47%;
    padding: desktop_size(193) 0 0 desktop_size(260);
    background: 20.8333vw 0 / 33.75vw auto no-repeat url("../img/circles-4.png");
    &.slide-up {

      .section-indicator {
        transition-duration: 1.5s;
        margin-top: 0px;
      }

      h3 {
        transition-duration: 1.5s;
        margin-top: 0px;
      }

      .text {
        &:nth-child(3) {
          transition-duration: 1.5s;
          transition-delay: 0.5s;
          margin-top: 0px;
          max-width: desktop_size(330);
        }
      }

      h6 {
        transition-duration: 1.5s;
        transition-delay: 0.7s;
        margin-top: desktop_size(35);
      }

      button {
        transition-duration: 1.5s;
        transition-delay: 1s;
        margin-top: desktop_size(35);
      }

    }

    .section-indicator {
      margin-top: desktop_size(50);
    }

    h3 {
      margin-top: desktop_size(50);
    }

    .text {
      &:nth-child(3) {
        margin-top: desktop_size(50);
      }
    }

    h6 {
      margin-top: desktop_size(70);
    }

    button {
      margin-top: desktop_size(70);
    }
  }
}