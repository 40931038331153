@media screen and (max-width: 760px) {
  .customers-wrapper {
    padding: 0;
    .customers {
      padding: mob_size(36) mob_size(6) 0;

      .title {
        order: 0;
        margin-top: mob_size(100);
        width: 100%;
        margin-bottom: 0;
        text-align: center;
        .section-indicator {
          margin-left: auto;
          margin-right: auto;
        }

        h3 {
          text-align: center;
        }
      }

      .customers-list-wrapper {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        flex-direction: column;
        height: auto;
        .title {
          margin-left: 0;

          .text {
            text-align: center;
            width: mob_size(260);
            margin-left: auto;
            margin-right: auto;
          }
        }

        .third-animation {
          order: 2;
          margin-top: 11.2vw;
          flex: 0 0 80vw;
          width: 80vw;
          margin-left: auto;
          margin-right: auto;
        }

        .customers-list {
          order: 1;
          flex: 0 0 auto;
          width: 100%;
          padding-top: 38px;

          .customers-list-item {
            width: 33%;
            height: calc((80 / 3.75) * 1vw);
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
            &:nth-child(10), &:nth-child(11), &:nth-child(12) {
              display: none;
            }
          }
        }
      }
    }
  }
}