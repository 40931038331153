@media screen and (max-width: 760px) {
  .about-us-wrapper {
    padding: 0 mob_size(20) mob_size(115);
    justify-content: flex-start;
    flex-direction: column;
    flex-wrap: nowrap;
    height: mob_size(845);

    &.after-load {
      .about-us {

        button {
          margin-top: mob_size(25);
        }
      }

      .first-animation {
        width: mob_size(324);
        margin-top: mob_size(87);
        flex: 0 0 auto;
      }
    }

    .about-us {
      padding-top: mob_size(32);
      width: 100%;
      order: 1;

      h1 {
        width: 100%;

        span {
          width: 100%;
        }
      }

      .text {
        width: 100%;
        font-size: mob_size(14);
        line-height: 175%;
        max-width: mob_size(325);

      }

      button {
        height: mob_size(60);
        padding: 0 mob_size(38);
        font-size: mob_size(16);
        border-radius: mob_size(15);
      }
    }

    .first-animation {
      width: mob_size(324);
      opacity: 0;
      order: 0;
      margin-left: auto;
      margin-right: auto;
    }
  }
}