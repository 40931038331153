@media screen and (max-width: 1024px) {
  header {
    align-items: center;
    padding: tablet_size(15) tablet_size(30) tablet_size(15) tablet_size(50);

    .logo {
      width: tablet_size(129);
      height: tablet_size(31);
    }

    menu {
      li {
        margin: 0 tablet_size(22);

        .menu-text {
          padding: tablet_size(5) 0;
        }
      }
    }
  }
}