@media screen and (max-width: 760px) {

    .popup {
      width: 100vw;
      height: 100vh;
      background-color: white;

      &.cookies-popup {
        background-color: white;
        .popup-content {
          .image {
            display: block;
            background-image: url("../img/popup-bottom2.png");
            height: mob_size(190);
            margin: mob_size(30) 0 mob_size(30) -35%;
          }
        }
      }

      &.quote-popup {
        background: transparent;
        width: 100vw;
        height: 100vh;
        border-radius: 0;

        .request-quote {
          .close {
            position: absolute;
            right: mob_size(25);
            top: mob_size(22);
          }
          .title {
            .section-indicator {
              height: 4px;
            }
            h3 {
              text-align: center;
              font-size: quote_mob_size(28);
              margin-bottom: quote_mob_size(20);
            }
            .text {
              text-align: center;
              width: mob_size(260);
              margin: 0 auto;
            }
          }

          width: 100vw;
          height: 100vh;
          border-radius: 0;
          max-width: 100vw;
          max-height: 100vh;
          padding: quote_mob_size(60) mob_size(40) quote_mob_size(11);
          overflow: hidden;

          form {
            text-align: center;
            margin-top: mob_size(50);
          }

          label {
            font-size: quote_mob_size(18);
            margin-bottom: quote_mob_size(11);
            h6 {
              font-size: quote_mob_size(18);
              margin-bottom: quote_mob_size(11);
            }

            input {
              border-bottom: 3px solid #E9EEF1;
              margin-bottom: quote_mob_size(35);
              font-family: Gilroy-regular, serif;
              font-size: quote_mob_size(18);
              padding-bottom: quote_mob_size(5);
              &:hover, &:active, &:focus {
                border: none;
                border-bottom: quote_mob_size(3) solid #a3aeeb;
                outline: 0;
              }
            }
          }

          button {
            padding: 0 mob_size(79);
            margin: quote_mob_size(13) auto quote_mob_size(30);
          }
          .request-img{
            height: quote_mob_size(142);
            width: quote_mob_size(295);
            background-image: url("../img/mob-request-img.png");
            background-size: cover;
            background-repeat: no-repeat;
            display: block;
          }
        }
      }

      &.open {

        &.request-quote {
          display: block;
        }
      }
      .line-for-close {
        position: fixed;
        width: 100%;
        background-color: white;
        height: mob_size(75);
        z-index: 1;
      }

      .close {
        width: mob_size(24);
        height: mob_size(24);
        top: mob_size(30);
        right: mob_size(34);
      }

      .popup-content {
        padding: mob_size(85) mob_size(30) 0;
        border-radius: 0;
        background: url(../img/circles-popup.png) 57.8646% 20.7vw/88% auto no-repeat, white;

        .title {
          .section-indicator {
            height: mob_size(4);
          }

          h3 {
            font-size: mob_size(28);
          }

          .text {
            width: mob_size(260);
            margin-bottom: mob_size(35);
          }
        }

        p.text {
          margin-top: mob_size(25);
        }

        .image {
          height: mob_size(216);
          margin-left: -38%;
        }
      }

      .popup-image {
        width: 0;
        height: 0;
      }
    }
  }