@media screen and (max-width: 1024px) {

  .popup {
    margin:auto;
    display: none;
    position: fixed;
    width: calc(100vw - 44px);
    height: calc(100vh - 44px);
    overflow: scroll;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 20;
    background-color: transparent;

    &.cookies-popup {
      background: transparent;
      .image {
        display: block;
        background-image: url("../img/popup-bottom2.png");
        width: 32.1875vw;
        height: 18.5417vw;
        margin: 4.6875vw auto 0;
      }
    }

    &.quote-popup {
      background: transparent;
      width: tablet_size(750);
      height: tablet_size(860);
      border-radius: 0;

      .request-quote {
        .close {
          position: absolute;
          right: tablet_size(60);
          top: tablet_size(60);
        }
        .title {
          .section-indicator {
            margin-left: auto;
            margin-right: auto;
            height: 5px;
          }
          h3 {
            text-align: center;
            font-size: tablet_size(36);
            margin-bottom: tablet_size(37);
          }
          .text {
            text-align: center;
            width: tablet_size(330);
            margin: 0 auto;
          }
        }

        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        width: tablet_size(750);
        height: tablet_size(880);
        background-color: white;
        border-radius: tablet_size(50);
        max-width: calc(100vw - 44px);
        max-height: calc(100vh - 44px);
        overflow: scroll;
        padding: tablet_size(80) tablet_size(165);

        form {
          text-align: center;
          margin-top: tablet_size(50);
        }

        label {
          width: 100%;
          text-align: left;
          font-size: tablet_size(18);
          line-height: 150%;
          margin-bottom: tablet_size(13);
          h6 {
            font-family: Gilroy-semibold, serif;
            font-size: tablet_size(18);
            line-height: 150%;
            margin-bottom: tablet_size(12);
          }

          input {
            width: 100%;
            border: none;
            border-bottom: 3px solid #E9EEF1;
            margin-bottom: tablet_size(35);
            font-family: Gilroy-regular, serif;
            font-size: tablet_size(18);
            line-height: 150%;
            padding-bottom: 3px;
            &:hover, &:active, &:focus {
              border: none;
              border-bottom: 3px solid #a3aeeb;
              outline: 0;
            }
          }
        }

        button {
          padding: 0 4.2708vw;
          margin: 0.78vw auto 0;
        }
        .request-img{
          height: tablet_size(188);
          width: tablet_size(395);
          background-image: url("../img/mob-request-img.png");
          background-size: cover;
          background-repeat: no-repeat;
          display: block;
        }
      }
    }

    &.open {
      display: flex;

      &.request-quote {
        display: block;
      }
    }

    .close {
      width: tablet_size(25);
      height: tablet_size(25);
      background-image: url("../img/close.png");
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      cursor: pointer;
      top: tablet_size(64);
      right: tablet_size(68);
      z-index: 2;
      position: fixed;
      &:hover {
        background-image: url("../img/close-hover.png");
      }
    }

    .popup-content {
      position: absolute;
      width: 100%;
      height: auto;
      overflow: visible;
      padding: tablet_size(80) tablet_size(165) 0;
      background: url(../img/circles-popup.png) 57.8646% 2.7vw/88% auto no-repeat, white;
      border-radius: 50px;

      .title {
        .section-indicator {
          margin-left: auto;
          margin-right: auto;
          height: tablet_size(6);
        }

        h3 {
          text-align: center;
          font-size: tablet_size(42);
        }

        .text {
          text-align: center;
          width: tablet_size(340);
          margin-left: auto;
          margin-right: auto;
          margin-bottom: tablet_size(57);
        }
      }

      p.text {
        //padding: 0 4.1146vw;
        margin-top: tablet_size(30);
        line-height: 170%;
      }

      .image {
        display: block;
        background-image: url("../img/popup-bottom1.png");
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        width: 163%;
        height: tablet_size(328);
        margin-left: -43%;
      }
    }

    .popup-image {
      width: 0;
    }
  }
}