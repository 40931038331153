@media screen and (max-width: 1024px) {

  .menu-text {
    font-size: tablet_size(16);
  }

  h1 {
    margin: 0 0 tablet_size(30);
    font-size: tablet_size(48);
  }

  h3 {
    margin: 0 0 tablet_size(30);
    font-size: tablet_size(36);
  }

  h4 {
    padding: 0;
    margin: 0 0 tablet_size(40);
    font-size: tablet_size(36);
  }

  h5 {
    margin: tablet_size(30) 0 tablet_size(20);
    font-size: tablet_size(22);
  }

  h6 {
    font-size: tablet_size(20);
  }

  .text {
    font-size: tablet_size(16);
  }

  .footer-text {
    font-size: tablet_size(16);
  }
}