@media screen and (max-width: 760px) {
  .menu-text {
    font-size: mob_size(20);
    line-height: 130%;
  }

  h1 {
    margin: 0 0 mob_size(20);
    font-size: mob_size(28);
    line-height: 130%;
  }

  h3 {
    margin: 0 0 mob_size(20);
    font-size: mob_size(28);
  }

  h4 {
    margin: 0 0 mob_size(40);
    font-size: mob_size(36);
  }

  h5 {
    margin: mob_size(30) 0 mob_size(15);
    font-size: mob_size(22);
  }

  h6 {
    font-size: mob_size(20);
  }

  .text {
    font-size: mob_size(14);
  }

  .footer-text {
    font-size: mob_size(16);
  }
}