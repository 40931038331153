@media screen and (max-width: 760px) {
  .services-wrapper {
    padding-top: mob_size(31);
    height: mob_size(830);

    .title {

      .text {
        width: mob_size(260);
        max-width: unset;
      }
    }

    .services-list {
      display: none;
      justify-content: space-between;
      flex-wrap: wrap;
      padding: mob_size(20) 0 mob_size(2);

      &.slider {
        display: block;
      }

      &.slide-up {
        .services-list-item {
          margin: mob_size(23) auto;
        }
      }

      .services-list-item {
        width: mob_size(320);
        height: mob_size(370);
        border-radius: mob_size(35);
        padding: mob_size(45) mob_size(30) mob_size(45) mob_size(40);
        margin: mob_size(23) auto;
        opacity: 1;
        box-shadow: 0 4px mob_size(35) rgba(172, 185, 192, 0.15);
        min-height: unset;
        max-width: unset;

        .service-icon {
          width: mob_size(60);
          height: mob_size(60);
        }

        .text {
          font-size: mob_size(14);
        }

        &.slide1 {
          .service-icon {
            background: url("../img/service-1.svg") center no-repeat;
          }
        }

        &.slide2 {
          transition-delay: 0.3s;

          .service-icon {
            background: url("../img/service-2.svg") center no-repeat;
          }
        }

        &.slide3 {
          transition-delay: 0.6s;

          .service-icon {
            background: url("../img/service-3.svg") center no-repeat;
          }
        }

        &.slide4 {
          transition-delay: 0.9s;

          .service-icon {
            background: url("../img/service-4.svg") center no-repeat;
          }
        }

        &.slide5 {
          transition-delay: 1.2s;

          .service-icon {
            background: url("../img/service-5.svg") center no-repeat;
          }
        }

        &.contact {
          padding-top: mob_size(47);
          padding-left: mob_size(60);
          background-color: $services-contact-bg-color;

          button {
            background-color: $button-services-bg-color;
            color: $button-services-text-color;
          }
        }
      }
    }

    .slick-dotted.slick-slider {
      margin-bottom: mob_size(36);
    }

    .second-animation {
      width: mob_size(353);
      margin: 0 auto 0;
    }

    .slick-dots {
      bottom: mob_size(-21);
      list-style: none;
      display: block;
      text-align: center;
      padding: 0;
      margin: 0;
      width: 100%;

      li {
        height: mob_size(7);
        width: mob_size(20);
        margin: 0 0;

        button {
          height: mob_size(7);
          width: mob_size(7);
          border-radius: mob_size(7);
          background-color: #DCDAFD;
          margin: 0 auto;
          padding: 0;

          &:before {
            display: none;
          }
        }

        &.slick-active {
          button {
            width: 100%;
            background-color: #4E66D2;
          }
        }
      }
    }
  }
}