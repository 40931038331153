@media screen and (max-width: 1024px) {
  .customers-wrapper {
    padding-top: tablet_size(73);
    .customers {
      padding: 0 tablet_size(33) 0 tablet_size(50);
      .title {
        width: auto;
        margin-bottom: tablet_size(35);
        .text {
          width: 26.8555vw;
        }
      }

      .customers-list-wrapper {
        justify-content: flex-start;
        flex-direction: column;
        height: tablet_size(630);
        .title {
          margin-left: tablet_size(50);
        }

        .third-animation {
          margin-top: 0;
          flex: 0 0 auto;
          width: tablet_size(360);
        }

        .customers-list {
          flex: 1 1 auto;
          justify-content: flex-start;
          //width: calc(100% - 355px);
          width: tablet_size(586);
          padding-top: tablet_size(63);
          max-width: unset;

          .customers-list-item {
            opacity: 0;
            width: 18.9453vw;
            height: 10vw;
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;

          }
        }
      }
    }
  }
}