.customers-wrapper {
  overflow: hidden;
  .customers {
    //padding: 64px 90px 77px 110px;
    padding: 3.33vw 4.69vw 4.01vw 5.73vw;
    background: 17.9167vw 0 / 77.3958vw auto no-repeat url("../img/circles-3.png");

    &.slide-up {
      .title {
        transition-timing-function: ease-out;
        transition-duration: 1.5s;
        margin-top: 0;
      }

      .customers-list-wrapper {
        .third-animation {
          transition-duration: 5s;
          transition-delay: 0.5s;
          opacity: 1;
        }
        .customers-list {
          .customers-list-item {
            transition-duration: 1.5s;
            opacity: 1;
            &:first-child {
              transition-delay: 0.2s;
            }
            &:nth-child(2) {
              transition-delay: 0.4s;
            }
            &:nth-child(3) {
              transition-delay: 0.6s;
            }
            &:nth-child(4) {
              transition-delay: 0.8s;
            }
            &:nth-child(5) {
              transition-delay: 1s;
            }
            &:nth-child(6) {
              transition-delay: 1.2s;
            }
            &:nth-child(7) {
              transition-delay: 1.4s;
            }
            &:nth-child(8) {
              transition-delay: 1.6s;
            }
            &:nth-child(9) {
              transition-delay: 1.8s;
            }
            &:nth-child(10) {
              transition-delay: 2s;
            }
            &:nth-child(11) {
              transition-delay: 2.2s;
            }
            &:nth-child(12) {
              transition-delay: 2.4s;
            }
          }
        }
      }
    }

    .title {
      margin-top: 5.21vw;
      width: 100%;
      margin-bottom: 0;
      margin-left: 7.81vw;
      .text {
        width: 17.71vw;
      }
    }

    .customers-list-wrapper {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      align-items: flex-start;

      .third-animation {
        opacity: 0;
        margin-top: 3.8vw;
        flex: 0 0 35.78vw;
        width: 35.78vw;
      }

      .customers-list {
        flex: 1 1 auto;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        max-width: 50vw;
        padding-top: 1.8vw;

        .customers-list-item {
          opacity: 0;
          width: 12.5vw;
          height: 10.42vw;
          background-position: center;
          background-size: contain;
          background-repeat: no-repeat;

          &:first-child,
          &:nth-child(3),
          &:nth-child(6),
          &:nth-child(8),
          &:nth-child(9),
          &:nth-child(11) {
            background-image: url("../img/customer1.png");
          }

          &:nth-child(2),
          &:nth-child(7),
          &:nth-child(10) {
            background-image: url("../img/customer2.png");
          }

          &:nth-child(4),
          &:nth-child(5),
          &:last-child {
            background-image: url("../img/customer3.png");
          }
        }
      }
    }
  }
}