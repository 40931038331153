@media screen and (max-width: 1024px) {
  footer {
    padding-left: tablet_size(100);

    .privacy {
      .footer-link {
        margin: 0 tablet_size(20);
      }
    }
  }
}