@font-face {
  font-family: 'Gilroy-semibold';
  src: url('../fonts/Gilroy-SemiBold.eot');
  src: url('../fonts/Gilroy-SemiBold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Gilroy-SemiBold.woff') format('woff'),
  url('../fonts/Gilroy-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Gilroy-bold';
  src: url('../fonts/Gilroy-Bold.eot');
  src: url('../fonts/Gilroy-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Gilroy-Bold.woff') format('woff'),
  url('../fonts/Gilroy-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Gilroy-regular';
  src: url('../fonts/Gilroy-Regular.eot');
  src: url('../fonts/Gilroy-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Gilroy-Regular.woff') format('woff'),
  url('../fonts/Gilroy-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Gilroy-medium';
  src: url('../fonts/Gilroy-Medium.eot');
  src: url('../fonts/Gilroy-Medium.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Gilroy-Medium.woff') format('woff'),
  url('../fonts/Gilroy-Medium.ttf') format('truetype');
}

.menu-text {
  font-family: Gilroy-semibold, serif;
  font-size: desktop_size(16);
  line-height: 125%;
  color: $menu-item-text-color;
}

h1 {
  padding: 0;
  margin: 0 0 desktop_size(30);
  font-family: Gilroy-bold, serif;
  font-size: desktop_size(58);
  line-height: 130%;
  color: $h1-color;
  text-transform: capitalize;

  span {
    color: $h1-span-color;
  }
}

h3 {
  padding: 0;
  margin: 0 0 desktop_size(30);
  font-family: Gilroy-bold, serif;
  font-size: desktop_size(48);
  line-height: 115%;
  color: $h3-color;
  text-transform: capitalize;

  span {
    color: $h3-span-color;
  }
}

h4 {
  padding: 0;
  margin: 0 0 desktop_size(55);
  font-family: Gilroy-bold, serif;
  font-size: desktop_size(36);
  line-height: 120%;
  color: $h4-color;
  text-transform: capitalize;

  span {
    color: $h4-span-color;
  }
}

h5 {
  padding: 0;
  margin: desktop_size(30) 0 desktop_size(20);
  font-family: Gilroy-semibold, serif;
  font-size: desktop_size(22);
  line-height: 150%;
  color: $h5-color;
  text-transform: capitalize;
}

h6 {
  padding: 0;
  margin: 0;
  font-family: Gilroy-bold, serif;
  font-size: desktop_size(20);
  line-height: 150%;
  color: $h6-color;
  text-transform: capitalize;
}

.text {
  font-family: Gilroy-regular, serif;
  font-size: desktop_size(18);
  line-height: 150%;
  color: $text-color;
}

.footer-text {
  font-family: Gilroy-medium, serif;
  font-size: desktop_size(18);
  line-height: 125%;
  color: $footer-text-color;
}