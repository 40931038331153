@function desktop_size ($base) {
  @return ($base/19.20) * 1vw;
  //@return $base * 1px;
}

@function tablet_size ($base) {
  @return ($base/10.24) * 1vw;
  //@return $base * 1px;
}

@function mob_size ($base) {
  @return ($base/3.75) * 1vw;
  //@return $base * 1px;
}

@function quote_mob_size ($base) {
  @return ($base/8.12) * 1vh;
  //@return $base * 1px;
}