$header-bg-color: #fff;
$menu-item-text-color: #353148;
$menu-item-text-hover-color: #96A4F4;
$menu-item-active-color: #4B61C3;
$menu-burger-color: #24285B;

$button-text-color: #FFFFFF;
$button-bg-color: #4B61C3;
$button-hover-bg-color: linear-gradient(0deg, #93A3F9, #93A3F9);
$button-services-bg-color: #fff;
$button-services-text-color: #212121;

$h1-color: #435FDA;
$h1-span-color: #000;
$h3-color: #24285B;
$h3-span-color: #4E66D2;
$h4-color: #fff;
$h4-span-color: #fddbdc;
$h5-color: #050307;
$h6-color: #4E66D2;

$slider-dot: #2D2D2D;
$slider-dot-active: #2D2D2D;

$text-color: #2D2D2D;

$footer-text-color: rgba(36, 40, 91, 0.5);
$footer-text-hover-color: #4B61C3;

$white-text: #fff;

$services-contact-bg-color: #7581CD;

$section-indicator-bg: linear-gradient(90deg, rgba(163, 183, 233, 0.35) 2.8%, rgba(255, 203, 204, 0.35) 100%), #FFD7D9;