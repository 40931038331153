@media screen and (max-width: 760px) {
  .team-wrapper {
    padding: mob_size(5) mob_size(17) mob_size(32);

    .team {
      border-radius: mob_size(35);
      padding: mob_size(50) mob_size(43) mob_size(60) mob_size(31);
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      .team-title-wrapper {
        display: none;
        &.mobile {
          display: block;
          order: 0;
        }
      }

      &.slide-up {
        .section-indicator {
          transition-timing-function: ease-out;
          transition: 1.5s;
          margin-top: 0;
        }

        h3 {
          transition-delay: 0.8s;
          transition-duration: 1.5s;
          margin-top: 0;
          font-size: mob_size(22);

          &.team-title {
            transition-delay: 0.5s;
            transition-duration: 1.5s;
            margin-top: 0;
            font-family: Gilroy-bold, serif;
            font-size: mob_size(28);
          }
        }
        .team-left {
          .linkedin {
            width: mob_size(30);
            height: mob_size(30);
            margin-top: mob_size(40);
          }
          .text {
            width: auto;
          }
        }
        .team-right {
          order: 1;
          height: mob_size(300);
          width: mob_size(266);
          margin-top: mob_size(8);
          margin-left: mob_size(-18);
        }
      }

      h3 {
        color: $white-text;
      }

      .team-left {
        order: 2;
        padding-top: 8px;

        h3 {
          margin-bottom: mob_size(22);
        }

        .text {
          width: auto;
        }

        .linkedin {
          width: mob_size(30);
          height: mob_size(30);
          margin-top: mob_size(60);
        }
      }

      .team-right {
        order: 1;
        height: mob_size(300);
        width: mob_size(266);
        background-image: url("../img/team.png");
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
      }
    }
  }
}