@media screen and (max-width: 1024px) {
  body {

    .page-wrapper {
      background: left 13.0859vw / auto 38.5742vw no-repeat url("../img/rectangle-1.png"),
      right 52.7344vw / auto 38.5742vw no-repeat url("../img/rectangle-2.png"),
      right 141.1133vw / auto 29.9805vw no-repeat url("../img/rectangle-3.png"),
      left 485.2539vw / auto 38.5742vw no-repeat url("../img/rectangle-5.png"),
      right bottom / 58.0729vw auto no-repeat url("../img/map.png"),
      9.5703vw 10.0586vw / 80% auto no-repeat url("../img/circles-1.png");

      button {
        height: tablet_size(54);
        padding: 0 tablet_size(39);
        font-size: tablet_size(16);
        border-radius: tablet_size(10);
      }

      .section-indicator {
        width: tablet_size(90);
        height: tablet_size(6);
        margin-bottom: tablet_size(30);
      }
    }
  }
}