#popup-bg {
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.3);
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 11;
}

.popup {
  margin: 0 auto;
  display: flex;
  position: fixed;
  width: 100vw;
  height: 100vh;
  overflow: scroll;
  top: 0;
  left: 0;
  right: 0;
  z-index: 20;
  background: url("../img/circles-popup.png") 7.8646% 3.125vw/ 80% auto no-repeat,
  white;
  opacity: 0;
  pointer-events: none;

  &.cookies-popup {
    background: url("../img/circles-popup.png") 7.8646% 3.125vw/ 80% auto no-repeat,
    white;
    .image {
      display: none;
    }
  }

  &.quote-popup {
    background: transparent;
    width: desktop_size(750);
    height: desktop_size(860);
    border-radius: 0;
    display: block;

    .request-quote {
      .close {
        position: absolute;
        right: desktop_size(60);
        top: desktop_size(60);
      }
      .title {
        .section-indicator {
          margin-left: auto;
          margin-right: auto;
          height: desktop_size(5);
        }
        h3 {
          text-align: center;
          font-size: desktop_size(36);
          margin-bottom: desktop_size(37);
        }
        .text {
          text-align: center;
          width: desktop_size(330);
          margin: 0 auto;
        }
      }

      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      width: desktop_size(750);
      height: desktop_size(860);
      background-color: white;
      border-radius: desktop_size(50);
      max-height: calc(100vh - 80px);
      padding: desktop_size(80) desktop_size(165);
      overflow: hidden;

      form {
        text-align: center;
        margin-top: desktop_size(50);
      }

      label {
        width: 100%;
        text-align: left;
        font-size: desktop_size(18);
        line-height: 150%;
        margin-bottom: desktop_size(13);
        h6 {
          font-family: Gilroy-semibold, serif;
          font-size: desktop_size(18);
          line-height: 150%;
          margin-bottom: desktop_size(12);
        }

        input {
          width: 100%;
          border: none;
          border-bottom: 3px solid #E9EEF1;
          margin-bottom: desktop_size(35);
          font-family: Gilroy-regular, serif;
          font-size: desktop_size(18);
          line-height: 150%;
          padding-bottom: desktop_size(3);
          transition: 0.5s;
          &:hover, &:active, &:focus {
            border: none;
            border-bottom: 3px solid #a3aeeb;
            outline: 0;
            transition: 0.5s;
          }
        }
      }

      button {
        padding: 0 4.2708vw;
        margin: 0.78vw auto 0;
      }
      .request-img {
        display: none;
      }
    }
  }

  &.open {
    opacity: 1;
    pointer-events: all;
    transition: 0.5s;
    .popup-content {
      opacity: 1;
      transition: 0.5s;
    }
  }

  .close {
    width: desktop_size(25);
    height: desktop_size(25);
    background-image: url("../img/close.png");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
    top: desktop_size(40);
    right: desktop_size(50);
    position: fixed;
    &:hover {
      background-image: url("../img/close-hover.png");
    }
  }

  .popup-content {
    width: 50%;
    height: 100%;
    overflow: auto;
    padding: desktop_size(80) desktop_size(165) desktop_size(129);
    opacity: 0;
    background: url(../img/circles-popup.png) 57.8646% 2.7vw/88% auto no-repeat, white;

    .title {
      .section-indicator {
        margin-left: auto;
        margin-right: auto;
        height: desktop_size(6);
      }

      h3 {
        text-align: center;
        font-size: desktop_size(42);
      }

      .text {
        text-align: center;
        width: desktop_size(340);
        margin-left: auto;
        margin-right: auto;
        margin-bottom: desktop_size(57);
      }
    }

    p.text {
      //padding: 0 4.1146vw;
      margin-top: desktop_size(30);
      line-height: 170%;
    }

    .image {
      display: none;
    }
  }

  .popup-image {
    height: 100%;
    width: 50%;
    background: url('../img/pop-up-img.png') center / 70% no-repeat,
    linear-gradient(0deg, #F5F6FF, #F5F6FF),
    linear-gradient(90deg, rgba(163, 183, 233, 0.1) 2.8%, rgba(255, 203, 204, 0.1) 100%),
    #FBFBFF;
  }
}