.team-wrapper {
  padding: 0 66px 66px;
  .team {
    background: linear-gradient(90deg, rgba(163, 183, 233, 0.35) 2.8%, rgba(255, 203, 204, 0.35) 100%), #4B61C3;
    border-radius: 100px;
    padding: 158px 228px 122px 195px;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    //height: 52.0297vw;
    .team-title-wrapper {
      display: block;
      &.mobile {
        display: none;
      }
    }
    &.slide-up {
      .team-left {
        .section-indicator {
          transition-timing-function: ease-out;
          transition: 1.5s;
          margin-top: 0;
        }

        h3 {
          transition-delay: 0.8s;
          transition-duration: 1.5s;
          margin-top: 0px;
          font-family: Gilroy-semibold, serif;
          &.team-title {
            transition-delay: 0.5s;
            transition-duration: 1.5s;
            margin-top: 0;
          }
        }
        .text {
          transition-delay: 1s;
          transition-duration: 1.5s;
          margin-top: 0;
          width: desktop_size(495);
          color: $white-text;
        }
        .linkedin {
          transition-delay: 1.2s;
          transition-duration: 1.5s;
          display: inline-block;
          width: desktop_size(36);
          height: desktop_size(36);
          background-image: url("../img/linkedin.png");
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          margin-top: desktop_size(205);
          opacity: 0.8;
          &:hover {
            opacity: 1;
          }
        }
      }
      .team-right {
        transition-delay: 0.3s;
        transition-duration: 1.5s;
        margin: 0;
      }

    }
    .team-left {
      padding-top: desktop_size(20);
      .section-indicator {
        margin-top: desktop_size(50);
        margin-bottom: desktop_size(35);
      }
      h3 {
        color: $white-text;
        margin-top: desktop_size(170);
        &.team-title {
          font-family: Gilroy-bold, serif;
        }
      }
      .text {
        margin-top: desktop_size(50);
        width: desktop_size(495);
        color: $white-text;
      }
      .linkedin {
        display: inline-block;
        width: desktop_size(36);
        height: desktop_size(36);
        background-image: url("../img/linkedin.png");
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        margin-top: desktop_size(220);
        opacity: 0.8;
        &:hover {
          opacity: 1;
        }
      }
    }

    .team-right {
      margin-top: desktop_size(50);
      width: desktop_size(552);
      height: desktop_size(720);
      background-image: url("../img/team.png");
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }
  }
}