header {
  position: fixed;
  top: 0;
  padding: desktop_size(35) desktop_size(112) desktop_size(35) desktop_size(100);
  width: 100%;
  display: flex;
  justify-content: space-between;
  background-color: $header-bg-color;
  z-index: 10;
  transition: 0.5s;
  &.after-scroll {
    box-shadow: 0 10px 30px rgba(163, 174, 235, 0.2);
    transition: 0.5s;
  }
  &.after-load {
    .logo {
      opacity: 1;
      transition: 1s;
    }
    menu {
      li {
        opacity: 1;
        transition: 1s;
        transition-delay: 0.6s;
        &:nth-child(5) {
          transition-delay: 1.2s;
        }
      }
    }
  }
  .logo {
    width: desktop_size(163);
    height: desktop_size(39);
    background-image: url("../logo.svg");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 0;
    margin-top: 7px;
  }
  menu {
    display: flex;
    align-items: center;
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      opacity: 0;
      margin: 0 desktop_size(40);
      border-bottom: 2px solid transparent;
      .menu-text {
        padding: 0 0 desktop_size(5);
        display: inline-block;
      }
      &:hover {
        .menu-text {
          color: $menu-item-text-hover-color;
        }
      }
      &:active, &.active {
        border-color: $menu-item-active-color;
        .menu-text {
          color: $menu-item-active-color;
        }
      }
      &:first-child {
        margin-left: 0;
      }
      &:nth-child(5) {
        margin-right: 0;
        border-color: transparent;
        .menu-text {
          &:first-child {
            display: none;
          }
        }
      }
      &:nth-child(6),
      &:nth-child(7),
      &:nth-child(8) {
        display: none;
      }
    }
  }
  input {
    display: none;
  }

  input + label {
    display: none;
  }
}