@media screen and (max-width: 1024px) {
  .team-wrapper {
    padding: tablet_size(6) tablet_size(21) 0;

    .team {
      border-radius: tablet_size(100);
      padding: tablet_size(101) tablet_size(66) tablet_size(79) tablet_size(76);

      &.slide-up {
        .team-left {

          .text {
            width: tablet_size(310);
            line-height: 165%;
          }

          .linkedin {
            margin-top: tablet_size(75);
            width: tablet_size(36);
            height: tablet_size(36);
          }
        }
        .team-right {
          margin-top: tablet_size(8);
        }
      }

      .team-left {
        padding-top: 0;
        .section-indicator {
          margin-bottom: tablet_size(30);
        }
        h3 {
          margin-bottom: tablet_size(40);
          &.team-title {
            margin-bottom: tablet_size(88);
          }
        }

        .text {
          width: tablet_size(310);
          line-height: 165%;
          color: $white-text;
        }

        .linkedin {
          margin-top: tablet_size(75);
          width: tablet_size(36);
          height: tablet_size(36);
        }
      }

      .team-right {
        margin-top: tablet_size(8);
        width: tablet_size(425);
        height: tablet_size(540);
      }
    }
  }
}