@media screen and (max-width: 760px) {
  body {

    .page-wrapper {
      background: right bottom / 100% auto no-repeat url("../img/map-mobile.png"),
      3.5938vw 8.4375vw / 90% auto no-repeat url("../img/circles-1.png");


      button {
        height: mob_size(60);
        padding: 0 mob_size(38);
        font-size: mob_size(18);
        border-radius: mob_size(10);
      }

      .section-indicator {
        width: mob_size(50);
        height: mob_size(4);
        margin-bottom: mob_size(20);
      }
    }
  }
}